import React from "react"

const JayGeiger = (
  <>
  <h4>US Navy Veteran</h4>
    <p>Bio coming soon.</p>
  </>
)

export default JayGeiger
