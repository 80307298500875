import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import UssIdahoFooterLogo from './uss-idaho-footer-logo'
import FooterMenuNewsletter from './FooterMenuNewsletter'

import * as styles from './footer.module.css'
import FooterNewsLetter from './FooterMenuNewsletter'
import JoinOurTeamModalButton from '../JoinOurTeam/JoinOurTeamModalButton'

const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Back to Homepage
    </Tooltip>
);

const Footer = () => (
<footer className="mt-auto">
    <Container fluid className="my-4 p-4">
        <Row className="h-100 align-items-top">
        <Col md={3} lg={2}>
        <h3 className={styles.menuTitle}>PCU Idaho Submarine</h3>
        <ul className={styles.footerLink}>
            <li>
                <Link to='/pcu-idaho-submarine#leadership'>Leadership</Link>
            </li>
            <li>
                <Link to='/pcu-idaho-submarine#sponsor'>Sponsor</Link>
            </li>
            <li>
                <Link to='/pcu-idaho-submarine#crew'>Crew</Link>
            </li>
            <li>
                <Link to='/pcu-idaho-submarine#ships-crest'>Crest</Link>
            </li>
        </ul>
        <h3 className={styles.menuTitle} style={{fontSize: '1rem'}}>The Boat</h3>
        <ul className={styles.footerLink}>
        <li>
                <Link to='/pcu-idaho-submarine#boat-capabilities'>Capabilties</Link>
            </li>
            <li>
                <Link to='/pcu-idaho-submarine#boat-mission'>Mission</Link>
            </li>
        </ul>
            </Col>
            <Col  sm={3} lg={2}>
            <h3 className={styles.menuTitle}>About Us</h3>
        <ul className={styles.footerLink}>
            <li>
                <Link to='/about-us'>Vision &amp; Mission</Link>
            </li>
            <li>
                <Link to='/about-us#commissioning-committee'>Commissioning Committee</Link>
            </li>
            <li>
                <Link to='/about-us#endorsements'>Endorsements</Link>
            </li>
            <li>
                <Link to='/about-us#committee-crest'>Committee Crest</Link>
            </li>
            <li>
                <Link to='/about-us#contact-us'>Contact Us</Link>
            </li>
        </ul>
        <h3 className={styles.menuTitle}>Construction &amp; Commissioning</h3>
        <ul className={styles.footerLink}>
            <li>
                <Link to='/construction-commissioning'>Construction Phases</Link>
            </li>
            <li>
                <Link to='/construction-commissioning'>Keel Laying</Link>
            </li>
            <li>
                <Link to='/construction-commissioning'>Christening</Link>
            </li>
            <li>
                <Link to='/construction-commissioning'>Commissioning</Link>
            </li>
            {/* <li>
                <Link to='/construction-commissioning'>Request Tickets</Link>
            </li> */}
        </ul>
        </Col>
         <Col md={3}>
        <h3 className={styles.menuTitle}>History</h3>
        <ul className={styles.footerLink}>
            <li>
                <Link to='/history#uss-idaho-ships'>USS IDAHO Ships</Link>
            </li>
            <li>
                <Link to='/history#idaho-naval-history'>Idaho Naval History</Link>
            </li>
            <li>
                <Link to='/history#idaho-national-guard'>Idaho National Guard</Link>
            </li>
            <li>
                <Link to='/history#idaho-army-history'>Idaho Army History</Link>
            </li>
            <li>
                <Link to='/history#idaho-air-force-history'>Idaho Air Force History</Link>
            </li>
            <li>
                <Link to='/history#idaho-submariners-on-eternal-patrol'>Idaho Submariners on Eternal Patrol</Link>
            </li>
            <li>
                <Link to='/history#idaho-congressional-medal-of-honor-recipients'>Idaho Congressional Medal of Honor Recipients</Link>
            </li>
            {/* <li>
                <Link to='/history#native-american-warriors'>Native American Warriors</Link>
            </li> */}
            <li>
                <Link to='/history#submarine-documentaries-books'>Submarine Documentaries &amp; Books</Link>
            </li>
            <li>
                <Link to='/history#idaho-military-history-documentaries-books'>Idaho Military History Documentaries &amp; Books</Link>
            </li>
        </ul>
        </Col>
        <Col md={3} lg={2}>
        <h3 className={styles.menuTitle}>Supporters</h3>
        <ul className={styles.footerLink}>
            <li>
                <Link to='/supporters/corporate'>Corporate</Link>
            </li>
            <li>
                <Link to='/supporters/small-business-civic'>Business/Civic Groups</Link>
            </li>
            <li>
                <Link to='/supporters/individuals'>Individuals</Link>
            </li>
            <li>
                <Link to='/supporters/partners'>Partners</Link>
            </li>
        </ul>
        <h3 className={styles.menuTitle}>News</h3>
        <ul className={styles.footerLink}>
            <li>
                <Link to='/upcoming-events'>Upcoming Events</Link>
            </li>
            <li>
                <Link to='/news/'>News Stories &amp; Articles</Link>
            </li>
            <li>
                <Link to='/events/'>Past Events</Link>
            </li>
            {/* <li>
                <Link to='/news'>Newsletters</Link>
            </li> */}
        </ul>
        <h3 className={styles.menuTitle}>Store</h3>
        <ul className={styles.footerLink}>
            <li>
                <Link to='/products'>Ship's Merchandise</Link>
            </li>
        </ul>
        </Col>

        <Col md={12} lg={3}>
        
        <div className="text-center" style={{ padding: '1rem'}}>
        <OverlayTrigger
                placement="top"
                deplay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
            >
            <div>
            <Link to="/">
                <UssIdahoFooterLogo />
            </Link>
            </div></OverlayTrigger>
            </div>
            
            <div className="text-center" >
                <p className={`text-center ${styles.footerText}`}>
                © {new Date().getFullYear()} {` `} USS Idaho Commissioning Committee
                </p>
                </div>
                <div className="text-center">
            <Link to="/donate">
                <Button className={`${styles.donateButton}`} >
                    Donate Now
                </Button>
            </Link>
            </div>
            <div className="text-center my-2 newsletterBtn">
                <JoinOurTeamModalButton 
                    btnTitle="Get Our Newsletter"
                />
            </div>
        {/* <FooterNewsLetter /> */}
        </Col>
        
        </Row>
    
    </Container>
<Row style={{backgroundColor: '#638FCB', padding: '.5rem'}}>
    <Col md={12} className="text-center">
        Website by <a href="https://inlightwebstudio.com" target="_blank" rel="noopener noreferrer" style={{color: '#FFF'}}>Inlight Web Studio</a>
    </Col>
</Row>
</footer>
)

export default Footer

