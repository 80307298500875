import React from 'react'
import { Col } from 'react-bootstrap'
import {useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import ModalImage from 'react-modal-image'
import styled from 'styled-components'

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  width: 100%;
`;


const ChristeningGallery = () => {
    const query = useStaticQuery(graphql`
    query {
        allContentfulGallery (filter: {galleryTitle: {eq: "Christening Gallery"}}) {
          edges {
            node {
              images {
                description
                fluid (maxWidth: 2000) {
                  srcWebp
                }
              }
            }
          }
        }
  christingPano:
  file(relativePath: { eq: "ChristeningPano.jpg" }) {
		childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
  }
}
  `)
  const photos = query.allContentfulGallery.edges[0].node.images;
  console.log(photos)

    return (
      <div style={{marginBottom: '2.5rem'}}>
        <div style={{marginBottom: '0.5rem'}}><Img fluid={query.christingPano.childImageSharp.fluid} style={{borderTopRightRadius: 'calc(.25rem - 1px)', borderTopLeftRadius: 'calc(.25rem - 1px)'}}/></div>
      <GalleryGrid>
              { photos.map((photo) => {
       return (         
           <ModalImage
            hideDownload
            hideZoom 
            small={photo.fluid.srcWebp}
            large={photo.fluid.srcWebp}
            alt={photo.description}
            className="rounded"
           />
          
       )
     }) }
     </GalleryGrid>
     </div>
    )
}

export default ChristeningGallery