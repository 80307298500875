import React from 'react'
import AdivsoryBoardModalButton from './AdvisoryBoardModalButton'
import { Row, Col } from 'react-bootstrap'
import {
     RichardFife,
     HenryNetzer,
     RichardMcPherson,
     MartySattison,
     RobertSkinner,
     DonHulse,
      TomDale,
     RussellJohnson,
     JayGeiger
 } from './AdvisoryBoardMembers'

 import GoldDolphin from "../../images/gold-dolphin.jpg"
 import SilverDolphin from "../../images/silver-dolphin.jpg"
 import HenryNetzerPhoto from "../../images/HenryNetzer.jpg"
 import MartySattisonPhoto from "../../images/MartySattison.jpg"
 import RichardFifePhoto from "../../images/RichardFife.jpg"
 import RobertSkinnerPhoto from "../../images/RobertSkinner.jpg"
 import RichardMcPhersonPhoto from "../../images/RichardMcPherson.jpg"
 import TomDalePhoto from "../../images/TomDale.jpg"
 import RussellJohnsonPhoto from "../../images/RussellJohnson.jpg"
 import JayGeigerPhoto from "../../images/JayGeiger.jpg"

export default function Regionalcommittees(props) {
    

    return (
        <>
            <Row className="my-4">
            <Col sm={6}>
                <h5>North Idaho</h5>
                <AdivsoryBoardModalButton
                modalContent={HenryNetzer}
                crewName="Henry Netzer"
                position="North Idaho Regional Chair"
                teamMemberPhoto={HenryNetzerPhoto}
                rank="Captain US Navy (ret)"
                emblem={GoldDolphin}
            />  
            </Col>
                <Col sm={6}>
                <h5>South Central Idaho</h5>
                <div>
                <AdivsoryBoardModalButton
                  modalContent={JayGeiger}
                  crewName="Jay Geiger"
                  position="Southcentral Idaho Regional Chair"
                  teamMemberPhoto={JayGeigerPhoto}
                  rank={
                    <>
                      Co-Founder
                      <br />
                      LCDR US Navy (ret)
                    </>
                  }
                />
            <p>
              <strong>Tony Kevan</strong>
              <br />
              <em>Vice Chair Southcentral Idaho Region</em>
              
            </p>
          </div>
            </Col>
            </Row>
            <Row className="my-4">
                <Col sm={6}>
                <div className="mb-4">
                <h5>South West Idaho</h5>
                <AdivsoryBoardModalButton
                modalContent={TomDale}
                crewName="Tom Dale"
                position="Southwest Idaho Regional Chair"
                teamMemberPhoto={TomDalePhoto}
                rank="Musician Second Class (MU2) US Navy Veteran MU2 "
                //emblem={SilverDolphin}
            />
            </div>   
            <div
          className="mb-4"
          style={{
            width: "100%",
            display: "flex",
          }}
        >
                      <div>
            <p>
              <strong>Bob Brady</strong><br />
  Vice Chair Southwest Idaho Region
  <br />Lieutenant US Navy veteran
            </p>
          </div>
          <div style={{ width: "120px" }}>
            <img src={GoldDolphin} style={{ width: "120px" }} />
          </div> 
        </div>

        <div
          className="mb-4"
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          {/* <div
            style={{
              width: "100px",
              marginRight: "10px",
            }}
          >
            <img
              className="rounded "
              src={TomDalePhoto}
              style={{
                width: "100px",
              }}
            />
          </div> */}
          <div>
            <p>
              <strong>Ron Dale</strong>
              <br />
              <em>Treasurer Southwest Idaho Region</em>
              
            </p>
          </div>
          {/* <div style={{ width: "120px" }}>
            <img src={SupplyOfficer} style={{ width: "120px" }} />
          </div> */}
        </div>
              </Col>
                <Col sm={6}>
                <h5>South East Idaho</h5>
                <div className="mb-4">
                <AdivsoryBoardModalButton
                modalContent={MartySattison}
                crewName="Marty Sattison"
                position="Southeast Idaho Regional Chair"
                teamMemberPhoto={MartySattisonPhoto}
                rank="Captain US Navy (ret)"
                emblem={GoldDolphin}
            /> </div>
               
            <div className="mb-4">
                <AdivsoryBoardModalButton
                modalContent={RobertSkinner}
                crewName="Robert Skinner"
                position="Vice Chair SE Region"
                teamMemberPhoto={RobertSkinnerPhoto}
                rank="Captain US Navy (ret)"
                emblem={SilverDolphin}
            /> </div>
            
            <div className="mb-4">
                <AdivsoryBoardModalButton
                modalContent={DonHulse}
                crewName="Don Hulse"
                position="Vice Chair Southern Area SE Region"
                //teamMemberPhoto={RobertSkinnerPhoto}
                rank="EMC (ss) US Navy (ret)"
                emblem={SilverDolphin}
            /> </div>
<div className="mb-4">
        <AdivsoryBoardModalButton
          modalContent={RussellJohnson}
          crewName="Russel Johnson"
          position="Vice Chair SE Region"
          teamMemberPhoto={RussellJohnsonPhoto}
        />
      </div>
        
        
                </Col>
            </Row>
        </>
    )
}


